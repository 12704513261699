<template>
  <div class="list-info">
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入姓名、手机号或身份证搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="cont">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" style="padding: 0 15px">
            <div class="content">
              <div class="content-text" style="margin: 0">
                <div class="top-text" style="line-height: 24px;">
                  姓名：{{item.name}}
                  <div class="right-text" :class="{'status1': item.auditStatus == 0, 'status2': item.auditStatus == -1}">{{item.auditStatus == 0 ? '待审核' : item.auditStatus == -1 ? '驳回' : '通过'}}</div>
                </div>
                <div class="bottom-text" style="margin-top: 0; line-height: 20px">手机号：{{item.mobile}}</div>
                <div class="bottom-text" style="margin-top: 0; line-height: 20px">身份证号：{{item.idNumber}}</div>
              </div>
            </div>
            <div style="border-top: 1px solid #EBF2FE; padding: 12px 0; display: flex;" v-if="item.auditStatus == 0">
              <div class="btn" style="margin-right: 20px; background: #F24C48" @click.stop="disagree(item)">驳回</div>
              <div class="btn" @click.stop="checkAgree(item)">通过</div>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
    <van-dialog v-model="show" show-cancel-button @confirm="check(selectItem, 1, 1)" @cancel="check(selectItem, 1, 0)">
      <div style="position: absolute; right: 20px; top: 18px; font-size: 18px; color: #999" @click="show=false">✕</div>
      <div style="width: 100%; text-align: center; line-height: 100px;">是否更新该手机号？</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      selectItem: null,
      pullLoading: false,
      totalCount: 0,
      searchValue: '',
      loading: false,
      finished: false,
      limit: 10,
      page: 0,
      dataList: []
    };
  },
  methods: {
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/audituserres/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          selectField: this.searchValue,
          orgId: this.$orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.totalCount = data.page.totalCount
          // 加载状态结束
          this.loading = false;
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
      // 数据全部加载完成
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    checkAgree (item) {
      this.selectItem = item
      this.show = true
      // this.$dialog.confirm({
      //   title: '是否更新该手机号？',
      // }).then(() => {
      //   this.check(item, 1, 1)
      // }).catch(() => {
      //   this.check(item, 1, 0)
      // })
    },
    disagree (item) {
      this.$dialog.confirm({
        title: '是否确认驳回该居民？'
      }).then(() => {
        this.check(item, -1)
      }).catch(() => {

      })
    },
    check(item, auditStatus, doUpdate) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/audituserres/update'),
        method: 'post',
        data: this.$http.adornData({
          id: item.id,
          userId: item.userId,
          mobile: item.mobile,
          doUpdate,
          auditStatus
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success({
            message: '操作成功！',
            duration: 1500,
            onOpened: () => {
              setTimeout(() => {
                this.$toast.clear()
                this.onPullDownRefresh()
              }, 1500);
            }
          })
        }
      },err=> {this.$toast.clear()})
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.search {
  padding-bottom: 20px !important;
}
.content {
  height: 160px !important;
}
.right-text {
  padding: 10px 32px;
  background: rgba(78,124,245,0.12);
  color: #4E7CF5 !important;
  font-size: 24px !important;
  line-height: 34px;
  top: 24px !important;
  right: 0 !important;
  border-radius: 8px;
}
.status1 {
  background: rgba(233,86,75,0.1);
  color: #E9564B !important;
}
.status2 {
  background: rgba(0,0,0,0.04);
  color: #999 !important;
}
.btn {
  flex: 1;
  width: 113px;
  line-height: 50px;
  padding: 0;
  border: 0;
  color: #fff;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 10px;
  background-color: #4E7CF5;
  color: #fff;
  text-align: center;
}
</style>
